<template>
  <div class="mb-4">
    <h4
      class="text-uppercase mb-3 primary--text"
      v-text="value.name"
    />

    <div class="pl-md-4">
      <v-row
        align="center"
        class="mb-2 mx-0"
      >
        <v-col
          v-for="(skill, i) in value.keywords"
          :key="i"
          class="fluid"
          cols="12"
          sm="6"
        >
          <v-chip
            background-color="grey darken-4"
          >
            {{ skill }}
          </v-chip>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          category: '',
          skills: [],
        }),
      },
    },
  }
</script>
